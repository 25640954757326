import './DeviceView.css'
import SettingsValue from './SettingsValue.js'
import EditableText from './EditableText.js'
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef, useContext, createContext } from 'react';
import checkimg from './check.png'
import ximg from './x.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {DeviceSettingsContext} from './DeviceView.js';
import {RangeStrsContext} from './SettingsPage.js';


export default function SettingsDisplayPage() {
    const {deviceSettings, setDeviceSettings} = useContext(DeviceSettingsContext);
    const rangeStrs = useContext(RangeStrsContext);

    return (
        <div>
            <div>
                <div className="ChemicalContainer">
                    <h2 className="TitleText ChemicalContainer_Title">pH</h2>
                    <div className="ChemicalContainer_Contents">
                        <p>Target: {deviceSettings.target_ph}</p>
                        <p>Offset: {deviceSettings.offset_ph}</p>

                        <p className="TabTitle">{rangeStrs.ph[0]}</p>
                        <div className="RowTab">
                            <p>Lower Border Add: {deviceSettings.target_ph_range0}</p>
                            <p>Pump Time: {deviceSettings.pumpTime_ph_range0}sec</p>
                            <p>Cooldown Time: {deviceSettings.cooldown_ph_range0}sec</p>
                        </div>

                        <p className="TabTitle">{rangeStrs.ph[1]}</p>
                        <div className="RowTab">
                            <p>Lower Border Add: {deviceSettings.target_ph_range1}</p>
                            <p>Pump Time: {deviceSettings.pumpTime_ph_range1}sec</p>
                            <p>Cooldown Time: {deviceSettings.cooldown_ph_range1}sec</p>
                        </div>

                        <p className="TabTitle">{rangeStrs.ph[2]}</p>
                        <div className="RowTab">
                            <p>Lower Border Add: {deviceSettings.target_ph_range2}</p>
                            <p>Pump Time: {deviceSettings.pumpTime_ph_range2}sec</p>
                            <p>Cooldown Time: {deviceSettings.cooldown_ph_range2}sec</p>
                        </div>
                    </div>
                </div>

                <div className="ChemicalContainer">
                    <h2 className="TitleText ChemicalContainer_Title">ORP</h2>
                    <div className="ChemicalContainer_Contents">
                        <p>Target: {deviceSettings.target_orp}mV</p>
                        <p>Offset: {deviceSettings.offset_orp}mV</p>

                        <p className="TabTitle">{rangeStrs.orp[0]}</p>
                        <div className="RowTab">
                            <p>Lower Border Add: {deviceSettings.target_orp_range0}mV</p>
                            <p>Pump Time: {deviceSettings.pumpTime_orp_range0}sec</p>
                            <p>Cooldown Time: {deviceSettings.cooldown_orp_range0}sec</p>
                        </div>

                        <p className="TabTitle">{rangeStrs.orp[1]}</p>
                        <div className="RowTab">
                            <p>Lower Border Add: {deviceSettings.target_orp_range1}mV</p>
                            <p>Pump Time: {deviceSettings.pumpTime_orp_range1}sec</p>
                            <p>Cooldown Time: {deviceSettings.cooldown_orp_range1}sec</p>
                        </div>

                        <p className="TabTitle">{rangeStrs.orp[2]}</p>
                        <div className="RowTab">
                            <p>Lower Border Add: {deviceSettings.target_orp_range2}mV</p>
                            <p>Pump Time: {deviceSettings.pumpTime_orp_range2}sec</p>
                            <p>Cooldown Time: {deviceSettings.cooldown_orp_range2}sec</p>
                        </div>
                    </div>
                </div>

                <div className="ChemicalContainer">
                    <h2 className="TitleText ChemicalContainer_Title">Clarifier/Solar Blanket</h2>
                    <div className="ChemicalContainer_Contents">
                        <p>Pump Frequency: {deviceSettings.frequency_clsb}sec</p>
                        <p>How Often to Save Progress to Flash: {deviceSettings.flash_frequency_clsb}sec</p>

                        <p className="TabTitle">Clarifier</p>
                        <div className="RowTab">
                            <p>Pump Time: {deviceSettings.pumpTime_cl}sec</p>
                            <p>Cooldown Time: {deviceSettings.cooldown_cl}sec</p>
                        </div>

                        <p className="TabTitle">Magnetic Stirrer</p>
                        <div className="RowTab">
                            <p>Pump Time: {deviceSettings.pumpTime_ms}sec</p>
                            <p>Cooldown Time: {deviceSettings.cooldown_ms}sec</p>
                        </div>

                        <p className="TabTitle">Solar Blanket</p>
                        <div className="RowTab">
                            <p>Pump Time: {deviceSettings.pumpTime_sb}sec</p>
                            <p>Cooldown Time: {deviceSettings.cooldown_sb}sec</p>
                        </div>
                    </div>
                </div>

                <div className="ChemicalContainer">
                    <h2 className="TitleText ChemicalContainer_Title">Temperature</h2>
                    <div className="ChemicalContainer_Contents">
                        <p>Fan Turn-On Temperature: {deviceSettings.target_temp}°F</p>
                        <p>Fan Time: {deviceSettings.pumpTime_fan}sec</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
