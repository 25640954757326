import './DeviceView.css'
import SettingsValue from './SettingsValue.js'
import EditableText from './EditableText.js'
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef, useContext, createContext } from 'react';
import checkimg from './check.png'
import ximg from './x.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {DeviceSettingsContext} from './DeviceView.js';
import {RangeStrsContext} from './SettingsPage.js';




export default function SettingsEditPage() {
    const rangeStrs = useContext(RangeStrsContext);

    return (
        <div>
            <div className="ChemicalContainer">
                <h2 className="TitleText ChemicalContainer_Title">About</h2>
                <div className="ChemicalContainer_Contents">
                    <SettingsValue text="Name: " name="name" wideTextbox={true}/>
                </div>
            </div>

            <div className="ChemicalContainer">
                <h2 className="TitleText ChemicalContainer_Title">pH</h2>
                <div className="ChemicalContainer_Contents">
                    <SettingsValue text="Target: " name="target_ph"/>
                    <SettingsValue text="Offset: " name="offset_ph"/>
                    
                    <p className="TabTitle">{rangeStrs.ph[0]}</p>
                    <div className="RowTab">
                        <SettingsValue text="Lower Border Add: " name="target_ph_range0"/>
                        <SettingsValue text="Pump Time: " name="pumpTime_ph_range0" 
                                postText="sec"/>
                        <SettingsValue text="Cooldown Time: " name="cooldown_ph_range0" 
                                postText="sec"/>
                    </div>
                    
                    <p className="TabTitle">{rangeStrs.ph[1]}</p>
                    <div className="RowTab">
                        <SettingsValue text="Lower Border Add: " name="target_ph_range1"/>
                        <SettingsValue text="Pump Time: " name="pumpTime_ph_range1" 
                                postText="sec"/>
                        <SettingsValue text="Cooldown Time: " name="cooldown_ph_range1" 
                                postText="sec"/>
                    </div>

                    <p className="TabTitle">{rangeStrs.ph[2]}</p>
                    <div className="RowTab">
                        <SettingsValue text="Lower Border Add: " name="target_ph_range2"/>
                        <SettingsValue text="Pump Time: " name="pumpTime_ph_range2" 
                                postText="sec"/>
                        <SettingsValue text="Cooldown Time: " name="cooldown_ph_range2" 
                                postText="sec"/>
                    </div>
                </div>
            </div>


            <div className="ChemicalContainer">
                <h2 className="TitleText ChemicalContainer_Title">ORP</h2>
                <div className="ChemicalContainer_Contents">
                    <SettingsValue text="Target: " name="target_orp" postText="mV"/>
                    <SettingsValue text="Offset: " name="offset_orp" postText="mV"/>
                    
                    <p className="TabTitle">{rangeStrs.orp[0]}</p>
                    <div className="RowTab">
                        <SettingsValue text="Lower Border Add: " name="target_orp_range0"
                                postText="mV"/>
                        <SettingsValue text="Pump Time: " name="pumpTime_orp_range0" 
                                postText="sec"/>
                        <SettingsValue text="Cooldown Time: " name="cooldown_orp_range0" 
                                postText="sec"/>
                    </div>
                    
                    <p className="TabTitle">{rangeStrs.orp[1]}</p>
                    <div className="RowTab">
                        <SettingsValue text="Lower Border Add: " name="target_orp_range1"
                                postText="mV"/>
                        <SettingsValue text="Pump Time: " name="pumpTime_orp_range1" 
                                postText="sec"/>
                        <SettingsValue text="Cooldown Time: " name="cooldown_orp_range1" 
                                postText="sec"/>
                    </div>

                    <p className="TabTitle">{rangeStrs.orp[2]}</p>
                    <div className="RowTab">
                        <SettingsValue text="Lower Border Add: " name="target_orp_range2"
                                postText="mV"/>
                        <SettingsValue text="Pump Time: " name="pumpTime_orp_range2" 
                                postText="sec"/>
                        <SettingsValue text="Cooldown Time: " name="cooldown_orp_range2" 
                                postText="sec"/>
                    </div>
                </div>
            </div>


            <div className="ChemicalContainer">
                <h2 className="TitleText ChemicalContainer_Title">Clarifier/Solar Blanket</h2>
                <div className="ChemicalContainer_Contents">
                    <SettingsValue text="Pump Frequency: " name="frequency_clsb" postText="sec"/>
                    <SettingsValue text="How Often to Save Progress to Flash: "
                        name="flash_frequency_clsb" postText="sec"/>
                    
                    <p className="TabTitle">Clarifier</p>
                    <div className="RowTab">
                        <SettingsValue text="Pump Time: " name="pumpTime_cl" postText="sec"/>
                        <SettingsValue text="Cooldown Time: " name="cooldown_cl" postText="sec"/>
                    </div>
                    
                    <p className="TabTitle">Magnetic Stirrer</p>
                    <div className="RowTab">
                        <SettingsValue text="Pump Time: " name="pumpTime_ms" postText="sec"/>
                        <SettingsValue text="Cooldown Time: " name="cooldown_ms" postText="sec"/>
                    </div>

                    <p className="TabTitle">Solar Blanket</p>
                    <div className="RowTab">
                        <SettingsValue text="Pump Time: " name="pumpTime_sb" postText="sec"/>
                        <SettingsValue text="Cooldown Time: " name="cooldown_sb" postText="sec"/>
                    </div>
                </div>
            </div>


            <div className="ChemicalContainer">
                <h2 className="TitleText ChemicalContainer_Title">Temperature</h2>
                <div className="ChemicalContainer_Contents">
                    <SettingsValue text="Fan Turn-On Temperature: " name="target_temp" 
                            postText="°F"/>
                    <SettingsValue text="Fan Time: " name="pumpTime_fan" 
                            postText="sec"/>
                </div>
            </div>
        </div>
    );
}
